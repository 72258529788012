<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                :absolute=true
                size="64"
            ></v-progress-circular>
	    </v-overlay>
        <v-data-table
            :headers="headers"
            :items="dataTable"
            :expanded.sync="expanded"
            dense
            item-key="index"
            :show-expand=true
            :loading= loadingTable
            class="elevation-1"
            @item-expanded="accountExpanded"
        >     
            <template v-slot:top>
                <v-toolbar flat>
                    <!-- <v-toolbar-title>
                    <v-icon>mdi-book-open-page-variant</v-icon> Libro mayor
                    </v-toolbar-title>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    ></v-divider>
                    <v-spacer></v-spacer> -->
                    <v-form ref="form_toolbar_search">
                        <v-col cols="12">
                            <v-row>
                                <v-col cols="12" md="2">
                                    <v-menu>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                class="pt-5"
                                                v-model="dateRangeText"
                                                label="Intervalo de fechas"
                                                persistent-hint
                                                readonly
                                                append-icon="mdi-calendar-multiple"
                                                v-bind="attrs"
                                                v-on="on"
                                                clearable
                                                click:clear="dates=''"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            range
                                            v-model="dates"
                                            no-title
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-autocomplete
                                        v-model=accountingMonth
                                        class="pt-5"
                                        label="Mes contable"
                                        :items=months
                                        item-value="number"
                                        item-text="name"
                                        clearable
                                        click:clear="accountingMonth =''"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-autocomplete
                                        v-model='accountingAccountFrom'
                                        class="pt-5"
                                        label="Cuentas contables desde"
                                        :items=accountingAccounts
                                        item-text="account_name"
                                        item-value="account_code"
                                        :rules=requiredSearch
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-autocomplete
                                        v-model='accountingAccountTo'
                                        class="pt-5"
                                        label="Cuentas contables hasta"
                                        :items=accountingAccounts
                                        item-text="account_name"
                                        item-value="account_code"
                                        :rules=requiredSearch
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-select
                                        label='Estado'
                                        class="pt-5"
                                        v-model=state
                                        :items=states
                                        item-value='state'
                                        item-text='name'
                                    >
                                    </v-select>
                                </v-col>
                        </v-row>
                        </v-col>
                    </v-form>
                    <v-btn small color="primary" @click="getDataComponent(false)">
                        <v-icon>mdi-magnify</v-icon> Buscar
                    </v-btn>
                    <v-btn small color="primary" @click="getDataComponent(true)" class="ml-md-1">
                        <v-icon>mdi-file-excel</v-icon> Descargar
                    </v-btn>
                </v-toolbar>
                <!-- <v-col cols="12">
                    <v-toolbar flat>
                        <v-row>
                            <div class="col-md-2">
                                <b>Cuenta contable:</b> <br />  {{codeAccountingAccount}}
                            </div>
                            <div  class="col-md-2">
                                <b>Saldo inicial:</b> <br />  USD {{initialBalance.toFixed(2)}}
                            </div>
                            <div  class="col-md-2">
                                <b>Periodo contable:</b> <br /> {{accountingPeriod}}
                            </div>
                            <div  class="col-md-2">
                                <b>Débito:</b><br /> USD {{totalDebits.toFixed(2)}}
                            </div>
                            <div  class="col-md-2">
                                <b>Crédito:</b> <p>  USD {{totalCredits.toFixed(2)}}</p>
                            </div>
                            <div  class="col-md-2">
                                <b>Saldo final:</b> <p> USD {{finalBalance.toFixed(2)}}</p>
                            </div>
                        </v-row>
                    </v-toolbar>
                </v-col> -->
            </template>  
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-col cols="12">
                        TRANSACCIONES DE LA CUENTA {{ item.account_name }}
                    </v-col>
                    <v-simple-table
                        dense
                        fixed-header
                    >
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-center">Nº asiento</th>
                                    <th class="text-center">Fecha</th>
                                    <th class="text-center">Tipo</th>
                                    <th class="text-center">Documento</th>
                                    <th class="text-center">Cliente</th>
                                    <th class="text-center">Proveedor</th>
                                    <th class="text-center">Descripción</th>
                                    <th class="text-left">Débito</th>
                                    <th class="text-left">Crédito</th>
                                </tr>
                            </thead>
                            <tbody v-if="item.details.length>0">
                                <tr 
                                    v-for="(detail, index) in item.details" 
                                    :key=index   
                                >
                                    <td>
                                        <a 
                                            target="_blank" 
                                            :href="`${urlExternal}/contabilidad/control/EditAcctgTrans/?acctgTransId=${detail.acctg_trans_id}&organizationPartyId=INNOCLINICA`">
                                            {{detail.acctg_trans_id}}
                                        </a>
                                    </td>
                                    <td style="width:150px">{{detail.transaction_date}}</td>
                                    <td style="width:180px">{{detail.desc_acctg_trans_type}}</td>
                                    <td>
                                        <a 
                                            target="_blank" 
                                            v-if="typeof detail.invoice_id != undefined"                                            
                                            :href="`${urlExternal}/ventas/control/invoiceOverview?invoiceId=${detail.invoice_id}`">
                                            {{detail.invoice_id}}
                                        </a>
                                        <a 
                                            v-if="typeof detail.payment_id != undefined"
                                            target="_blank" 
                                            :href="`${urlExternal}/contabilidad/control/paymentOverview?paymentId=${detail.payment_id}`">
                                            {{detail.payment_id}}
                                        </a>
                                    </td>
                                    <td>
                                        <span v-if="typeof detail.client!= undefined">{{detail.client}}</span>
                                        <span v-if="typeof detail.pay_client!= undefined">{{detail.pay_client}}</span>
                                    </td>
                                    <td>
                                        <span v-if="typeof detail.provider!= undefined">{{detail.provider}}</span>    
                                        <span v-if="typeof detail.pay_provider!= undefined">{{detail.pay_provider}}</span>
                                    </td>
                                    <td>{{detail.description}}</td>
                                    <td style="width:150px">{{detail.currency_uom_id+' ' +(detail.debit_credit_flag  == 'D'  ? detail.amount : 0)}}</td>
                                    <td style="width:150px">{{detail.currency_uom_id+' ' +(detail.debit_credit_flag  == 'C' ? detail.amount : 0)}}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="9" class='text-center'>
                                        No se encontratron registros
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </td>
            </template>
            <!-- 
            <template v-slot:footer>
                <v-col cols="12">
                    <v-toolbar flat>
                        <v-row>
                            <div class="col-md-2">
                                <b>Cuenta contable:</b> <br />  {{codeAccountingAccount}}
                            </div>
                            <div  class="col-md-2">
                                <b>Saldo inicial:</b> <br /> USD {{initialBalance.toFixed(2)}}
                            </div>
                            <div  class="col-md-2">
                                <b>Periodo contable:</b> <br /> {{accountingPeriod}}
                            </div>
                            <div  class="col-md-2">
                                <b>Débito:</b><br /> USD {{totalDebits.toFixed(2)}}
                            </div>
                            <div  class="col-md-2">
                                <b>Crédito:</b> <p> USD {{totalCredits.toFixed(2)}}</p>
                            </div>
                            <div  class="col-md-2">
                                <b>Saldo final:</b> <p> USD {{finalBalance.toFixed(2)}}</p>
                            </div>
                        </v-row>
                    </v-toolbar>
                </v-col>
            </template>
            <template v-slot:item.acctg_trans_id="{ item }">
                <a target="_blank" :href="`${urlExternal}/contabilidad/control/EditAcctgTrans?acctgTransId=${item.acctg_trans_id}&organizationPartyId=${partyInternalOrganization}`">
                    {{item.acctg_trans_id}}
                </a>
            </template> -->
        </v-data-table>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
    name:"UnifiedExpenseReportComponent",
    data: ()=>({
        expanded: [],
        dataTable:[],
        options: {},
        dates: [],
        accountingMonths:[],
        accountingAccounts:[],
        headers: [
            { text: "Nº cuenta", value: "account_code" },
            { text: "Nombre", value: "account_name" },
            { text: "Saldo Inicial", value: "initial_balance" },
            { text: "Saldo final", value: "final_balance" },
            { text: 'Detalle', value: 'data-table-expand' }
        ],
        states:[
            {state: 'Y', name: 'Procesadas'},
            {state: 'N', name: 'No procesadas'}
        ],
        hostname: window.location.protocol+'//'+window.location.hostname,
        overlay:false,
        accountingMonth:'',
        codeAccountingAccount:'',
        accountingAccountFrom:'',
        accountingAccountTo:'',
        state:'Y',
        requiredSearch:[
            v => !!v || 'El campo es requerido'
        ]
    }),
    computed: {
		...mapState('master',['loadingTable','url','months','urlExternal']),

		dateRangeText () {
			return this.dates.join(' ~ ')
        },
    },
    methods:{
        ...mapMutations('master',['setUrl','setLoadingTable','setLoadingBtn']),

        ...mapActions('master',['errorRequest','requestApi','alertNotification']),

        getAccountingAccounts(){
            this.setUrl('reporte-unificado-de-gastos/1')
            this.requestApi({
				method: 'PATCH',
				data :{}
			}).then(res =>{
                this.accountingAccounts = res.data.glAccounts
			})
        },

        getDataComponent(download){
            if(!this.$refs.form_toolbar_search.validate())
                return

            this.setLoadingTable(true)
            this.setUrl('reporte-unificado-de-gastos')
            this.requestApi({
				method: 'GET',
				data: {
                    accountingAccountFrom : this.accountingAccountFrom,
                    accountingAccountTo : this.accountingAccountTo,
                    accountingMonth : this.accountingMonth,
                    dates : this.dateRangeText,
                    state : this.state,
                    download: download
                }
			}).then(res =>{
                if(!download){
                    this.dataTable = res.data._embedded.reporte_unificado_de_gastos.map((obj,index) => {
                        obj.index = index
                        obj.details =[]
                        return obj
                    })
                }else{
                    let link = document.createElement("a");
                    link.href = res.data.file;
                    link.download = "Reporte libro mayor con saldos "+this.dateRangeText+" "+this.accountingAccount+".xlsx";
                    link.click();
                }
                this.setLoadingTable(false)
			})
        },

        accountExpanded(obj){
            console.log(obj)
            if(obj.value){
                this.overlay=true
                this.setUrl('reporte-unificado-de-gastos')
                obj.dates = this.dateRangeText
                obj.accountingMonth = this.accountingMonth

                this.requestApi({
                    method: 'PUT',
                    data: {
                        data : obj
                    }
                }).then(res =>{
                    console.log( res.data._embedded.reporte_unificado_de_gastos)
                    this.overlay=false
                    let objDataTable = this.dataTable.find(e => e.gl_account_id == obj.item.gl_account_id)
                    let index = this.dataTable.indexOf(objDataTable)
                    this.dataTable[index].details= res.data._embedded.reporte_unificado_de_gastos
                }).catch(error=>{
                    this.overlay=false
                })

            }
        },

        downloadReport(){

        }
    },
    mounted(){
       this.getAccountingAccounts()
    }
}
</script>